const setRequiredForms = (sections, dashboard) => {
    dashboard.forEach((cat) => {
        cat.cards.forEach((card) => {
            if (card.worksheet?.length) {
                const foundSection = sections.filter(x => x.id !== 'Government' && card.worksheet.find(worksheet => x.id.startsWith(worksheet.split('\\')[worksheet.split('\\').length - 1]) && x.section.startsWith(worksheet.split('\\')[worksheet.split('\\').length - 2])));
                const foundGovtForms = sections.filter(x => x.id === 'Government' && card.worksheet.find(worksheet => worksheet.split('\\')[worksheet.split('\\').length - 1] === 'Government' && x.section.startsWith(worksheet.split('\\')[worksheet.split('\\').length - 2])));
                if (foundGovtForms.length > 0) {
                    //logic to update card required/optional status    
                    updateCardReq(card, foundGovtForms);
                }
                else if (foundSection.length) {
                    if (card.worksheetSections) {
                        // Iterate over each worksheetSection in the card
                        const hasPriorData = card.worksheetSections.some(workSheetSec => {
                            // Find matching sections in foundSubSections based on the worksheetSection name
                            const matchingSubSection = foundSection.find(section =>
                                section.data.find(x => x.name.startsWith(workSheetSec))
                            );

                            // If a matching subsection is found, check if it has fields or lineItems
                            if (matchingSubSection) {
                                return matchingSubSection.data.some(subSection =>
                                    subSection.name.startsWith(workSheetSec) && (subSection.fields || subSection.lineItems)
                                );
                            }

                            return false; // No match found for this workSheetSec
                        });

                        // Set hasPriorData to true if any matching section has fields or lineItems
                        if (hasPriorData) {
                            card.hasPriorData = true;
                        }

                    } else if (card.worksheetSection) {
                        // Handle single worksheetSection case similarly
                        const foundSubSection = foundSection.find(section =>
                            section.data.find(x => x.name.startsWith(card.worksheetSection))
                        );

                        if (foundSubSection) {
                            const hasPriorData = foundSubSection.data.some(subSection =>
                                subSection.name.startsWith(card.worksheetSection) && (subSection.fields || subSection.lineItems)
                            );

                            if (hasPriorData) {
                                card.hasPriorData = true;
                            }
                        }

                    } 

                }
            }
        });
    });
    return dashboard;
};

const updateCardReq = (card, foundGovtForms) => {
    switch (card.title) {
        case 'Tax Payments':
            checkTaxPaymentsReq(card, foundGovtForms);
            break;
        case 'Other Sources of Income':
            checkOtherIncomeReq(card, foundGovtForms);
            break;
        default:
            card.hasPriorData = true;
            break;
    }
};

const checkTaxPaymentsReq = (card, foundGovtForms) => {
    if (foundGovtForms.length > 0) {
        const stateLinkFields = ['G20632.66', 'G20632.49', 'G20632.50', 'G20632.51', 'G20632.52'];
        const federalLinkFields = ['G20631.39', 'G20631.40', 'G20631.41', 'G20631.42'];
        foundGovtForms.forEach((foundGovtForm) => {
            if (foundGovtForm.section === 'State Link Worksheet') {
                card.isRequired = card.isRequired;
            }
            else if (foundGovtForm.section === 'Federal Link Worksheet') {
                card.isRequired = card.isRequired;
            }
            card.hasPriorData = true;
        });
    }
};

const checkOtherIncomeReq = (card, foundGovtForms) => {
    if (foundGovtForms.length > 0) {
        // This list will match to target Government fields in the Misc Income form
        const stateLinkFields = [
            'G20632.14',
            'G20632.15',
            'G20632.34',
            'G20632.37',
            'G20632.35',
            'G20632.83',
            'G20632.38',
            'G20632.40',
            'G20632.41',
            'G20632.66',
            'G20632.43',
            'G20632.44',
            'G20632.45',
            'G20632.46',
            'G20632.47',
            'G20632.48',
            'G20632.36',
            'G20632.9',
            'G20632.10',
            'G20632.84',
            'G20632.39',
            'G20632.11',
            'G20632.57',
            'G20632.65',
            'G20632.56',
            'G20632.81',
            'G20632.59',
            'G20632.60',
            'G20632.61',
            'G20632.62',
        ];

        foundGovtForms.forEach((foundGovtForm) => {
            if (foundGovtForm.section === 'State Link Worksheet') {
                card.isRequired = card.isRequired;
            }
            card.hasPriorData = true;
        });
    }
};

export {
    setRequiredForms
};