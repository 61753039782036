// common modules
import React from 'react';
import KeyStatisticTile from '@components/KeyStatisticTile.js';
import { ALL_CHECKLIST_KEY } from '@utilities/constants/strings';

function ChecklistNotDownloaded(props) {
  const { tileData, label, tileKey, isToggled, handleCardClick } = props;
  const value = tileData?.keyStatistics?.[tileKey] || 0;
  const total = tileData?.keyStatistics?.[ALL_CHECKLIST_KEY];

  let percent = 0;
  if (total !== 0) {
    percent = Math.round((value / total) * 100);
  }

  return (
    <KeyStatisticTile
      key={`statistics-tile-${tileKey}`}
      label={label}
      total={value}
      completed={percent}
      isToggled={isToggled}
      handleTileClick={handleCardClick(tileKey)}
    />
  )
}

export default ChecklistNotDownloaded;