import { getCardProp } from '@utilities/helpers/getCardProp';
import DashboardCardBase from './dashboardCardBase';
import { taxReturnStatuses } from '@utilities/constants/taxReturn';
import { TAX_RETURN_DOWNLOAD_ACTIVE, TAX_RETURN_DOWNLOAD_INACTIVE } from '@utilities/constants/images';
import { DOWNLOAD_BUTTON_TEXT, TAX_RETURN_DOWNLOAD_INACTIVE_TEXT } from '@utilities/constants/strings';
import api from '@utilities/claApi';
import './dashboardCard.css';

const TaxReturnCard = ({ card: { title, formName }, taxReturnStatus, organizerId }) => {
    let cardStyle = {};
    let cardProgressLabelStyle = { margin: '5px 0 0 5px' };
    let cardProgressLabelText = '';
    let cardIcon = TAX_RETURN_DOWNLOAD_ACTIVE;

    const taxReturnCopyFileName = "Tax_Return_Copy.pdf";

    if (taxReturnStatus === taxReturnStatuses.signed) {
        cardProgressLabelText = DOWNLOAD_BUTTON_TEXT;
    } else {
        cardProgressLabelStyle = {
            ...cardProgressLabelStyle,
            fontSize: '10px'
        };

        cardStyle = {
            cursor: 'not-allowed',
            opacity: 0.6
        }

        cardProgressLabelText = TAX_RETURN_DOWNLOAD_INACTIVE_TEXT;
        cardIcon = TAX_RETURN_DOWNLOAD_INACTIVE;
    }

    const handleTaxReturnCardClick = async () => {  
        try {
            if (taxReturnStatus === taxReturnStatuses.signed) {   
                const response = await api.get(`/organizers/${organizerId}`);  
                if(!response){
                    console.error("Organizer not found");
                    return;
                }

                const blobFile =  await api.get(`/tax-return/${response?.data?.envelopeId}/downloadDocument`,
                        { responseType: 'blob' }).then((res) => res.data);   
                
                const url = URL.createObjectURL(blobFile);
                const a = document.createElement("a");
                a.href = url;
                a.download = taxReturnCopyFileName;
                document.body.appendChild(a);
                a.click();

                setTimeout(function () {
                    document.body.removeChild(a);
                    URL.revokeObjectURL(blobFile);
                }, 1000);
                  
            }   
        } catch (error) {
            console.error(error.message);
        }    
    }

    return (
        <DashboardCardBase
            data-test-id="taxReturnCard"
            cardTitle={title}
            cardSubtitle={getCardProp(formName, 'subtitle')}
            cardIcon={cardIcon}
            cardProgressLabel={cardProgressLabelText}
            onCardClick={handleTaxReturnCardClick}
            cardStyle={cardStyle}
            cardProgressLabelStyle={cardProgressLabelStyle} />
    );
}

export default TaxReturnCard;
