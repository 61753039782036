import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import household2021 from './2021';
import household2022 from './2022';
import household2023 from './2023';
import household2024 from './2024';
import * as NAV from '@utilities/constants/navigation';

function HouseholdEmploymentTaxes(props) {
    const { isExportForm } = props
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.HOUSEHOLDEMPLOYMENTTAXES);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: household2021(year),
            2022: household2022(year),
            2023: household2023(year),
            2024: household2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    return (
        <FormRenderer sections={sectionState} key="householdEmploymentTaxesForm" formName={REDUX.HOUSEHOLD_EMPLOYMENT} identifiers={[ { section: 'Taxes', id: 'Sch H' } ]} isExportForm={isExportForm}/>
    );
}

export default HouseholdEmploymentTaxes;
