import * as NAV from '@utilities/constants/navigation';
import * as IMG from '@utilities/constants/images';
import * as STR from '@utilities/constants/strings';
import * as REDUX from '@utilities/constants/redux';
import * as H1s from '@utilities/constants/formSectionHeader';
import * as STATUS from '@utilities/constants/cardStatus';

const dashboard = [
	{
		title: STR.REQUIRED_INFORMATION,
		entryType: 'Personal',
		cards: [
			{
				title: STR.ABOUT_YOU,
				entryType: 'Personal',
				key: 'dashCard-1-1',
				formName: REDUX.ABOUT_YOU,
				statusId: 1,
				route: NAV.ABOUT_YOU,
				dashBoardIcon: IMG.REQUIRED_INFORMATION,
				showBulkUpload: false,
				worksheet: ['Federal\\General\\Basic Data'],
				worksheetSection: 'General',
				notes: [],
				statusDefault: STATUS.REQUIRED,
				isRequired: true,
				sectionHeader: H1s.ABOUT_YOU,
				uploadSectionName: 'aboutYouGeneralUploads'
			},
			{
				title: STR.DEPENDENTS,
				entryType: 'Personal',
				key: 'dashCard-1-2',
				formName: REDUX.DEPENDENTS,
				statusId: 1,
				route: NAV.DEPENDENTS,
				dashBoardIcon: IMG.DEPENDENTS,
				showBulkUpload: false,
				worksheet: ['Federal\\General\\Basic Data'],
				worksheetSection: 'Dependents',
				notes: [],
				isRequired: false,
				sectionHeader: H1s.DEPENDENTS,
				uploadSectionName: 'dependentsGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{
				title: STR.ABOUT_YOUR_YEAR,
				entryType: 'Personal',
				key: 'dashCard-1-3',
				formName: REDUX.ABOUT_YOUR_YEAR,
				statusId: 1,
				route: NAV.ABOUT_YOUR_YEAR,
				dashBoardIcon: IMG.ABOUT_YOUR_YEAR,
				showBulkUpload: false,
				notes: [],
				statusDefault: STATUS.REQUIRED,
				isRequired: true,
				sectionHeader: H1s.ABOUT_YOUR_YEAR,
				uploadSectionName: 'aboutYourYearGeneralUploads'
			},
			{
				title: STR.REFUND_PAYMENT_PREFERENCES,
				entryType: 'Personal',
				key: 'dashCard-1-4',
				formName: REDUX.REFUND_TAX_PAYMENT,
				statusId: 1,
				route: NAV.REFUNDTAXPAYMENT,
				dashBoardIcon: IMG.REFUND_PAYMENT_OPTIONS,
				showBulkUpload: false,
				worksheet: ['Federal\\General\\Basic Data'],
				worksheetSection: 'Direct Deposit/Electronic Funds Withdrawal',
				notes: [],
				isRequired: true,
				statusDefault: STATUS.REQUIRED,
				sectionHeader: H1s.REFUND_TAX_PAYMENT,
				uploadSectionName: 'refundPaymentsGeneralUploads'
			},
			{
				title: STR.TAX_PAYMENTS,
				entryType: 'Personal',
				key: 'dashCard-1-5',
				formName: REDUX.TAX_PAYMENTS,
				statusId: 1,
				route: NAV.TAX_PAYMENTS,
				dashBoardIcon: IMG.TAX_PAYMENTS,
				showBulkUpload: false,
				worksheet: [
					'Federal\\Payments/Penalties/Estimates\\Estimates',
					'Federal\\Federal Link Worksheet\\Government',
					'Federal\\State Link Worksheet\\Government',
					'Installment Information\\Payments/Penalties/Estimates\\Estimates'
				],
				worksheetSections: ['Installment Information', 'Federal'],
				notes: [],
				isRequired: false,
				sectionHeader: H1s.TAX_PAYMENTS,
				uploadSectionName: 'taxPaymentsGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{
				title: STR.FOREIGN_ASSETS,
				entryType: 'Personal',
				key: 'dashCard-1-6',
				formName: REDUX.FOREIGN_ASSETS,
				statusId: 1,
				route: NAV.FOREIGN_ASSETS,
				entryIconInactive: IMG.ENTRY_HELD_FOREIGN_ASSETS,
				dashBoardIcon: IMG.WORKED_OUTSIDE_THE_US,
				showBulkUpload: false,
				worksheet: [
					'Federal\\Foreign\\114 (FBAR) / 8938 - Foreign Assets'
				],
				worksheetSection: 'Report of Foreign Bank and Financial Accounts',
				notes: [],
				isRequired: false,
				isRecommended: false,
				sectionHeader: H1s.FOREIGN_ASSETS,
				entryTitle: STR.ENTRY_EXP_FOREIGN_ASSETS,
				uploadSectionName: 'foreignAssetsGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
		]
	},
	{
		title: STR.RECOMMENDED_INFORMATION,
		entryType: 'Income',
		cards: [
			{ 
				title: STR.WAGES, 
				entryType: 'Income',
				key: 'dashCard-2-1', 
				formName: REDUX.WAGES, 
				statusId: 1, 
				route: NAV.WAGES, 
				entryIconInactive: IMG.ENTRY_EARNED_WAGES_W2_INACTIVE, 
				dashBoardIcon: IMG.EARNED_WAGES_W2, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Income\\Wages, Salaries and Tips (W-2)'], 
				worksheetSection: 'Wages and Salaries (IRS W-2)', 
				notes: [], 
				isRequired: false,
				isRecommended: false,
				sectionHeader: H1s.WAGES, 
				entryTitle: STR.ENTRY_EXP_WAGES, 
				uploadSectionName: 'wagesGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.INVESTMENT_INCOME, 
				entryType: 'Income',
				key: 'dashCard-2-2', 
				formName: REDUX.INVESTMENT_INCOME, 
				statusId: 1,
				route: NAV.INCOME, 
				entryIconInactive: IMG.ENTRY_RECEIVED_INVESTMENT_INCOME_INACTIVE, 
				dashBoardIcon: IMG.RECEIVED_INVESTMENT_INCOME, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Income\\Dividends', 'Federal\\Income\\Interest', 'Federal\\Income\\Consolidated 1099'], 
				worksheetSections: ['Dividens (IRS 1099-DIV)', 'Interest (IRS 1099-INT', 'General', 'Investment Interest' ],
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.INVESTMENT_INCOME, 
				entryTitle: STR.ENTRY_EXP_INVESTMENT, 
				uploadSectionName: 'investmentIncomeGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.RETIREMENT_INCOME, 
				entryType: 'Income',
				key: 'dashCard-2-3', 
				formName: REDUX.RETIREMENT_INCOME, 
				statusId: 1,
				route: NAV.RETIREMENT_INCOME, 
				entryIconInactive: IMG.ENTRY_RECEIVED_RETIREMENT_INCOME_INACTIVE, 
				dashBoardIcon: IMG.RECEIVED_RETIREMENT_INCOME, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Income\\IRAs, Pensions and Annuities (1099-R)', 'Federal\\Income\\Social Security Benefit'], 
				worksheetSections: ['Distributions from Pensions, Annuities and IRAs (IRS 1099-R', 'IRS Information', 'Social Security Benefit Statement (SSA-1099)'],
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.RETIREMENT_INCOME, 
				entryTitle: STR.ENTRY_EXP_RETIREMENT_INCOME, 
				uploadSectionName: 'retirementIncomeGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.BUSINESS_INCOME, 
				entryType: 'Income',
				key: 'dashCard-2-4', 
				formName: REDUX.BUSINESS_INCOME, 
				statusId: 1, 
				route: NAV.BUSINESS_INCOME, 
				entryIconInactive: IMG.ENTRY_HAD_BUSINESS_INCOME_INACTIVE, 
				dashBoardIcon: IMG.HAD_BUSINESS_INCOME, 
				showBulkUpload: false, 
				showBulkEntityUpload: true, 
				bulkUpload: 
					{ 
						title: STR.BUSINESS_INCOME_BULK_UPLOAD_TITLE, 
						subTitle: STR.BUSINESS_INCOME_BULK_UPLOAD_SUBTITLE, 
						sectionName: 'scheduleC1099Uploads', 
						uploadList: [], 
						expanded: false, 
						buttonState: 'active', 
						isLoaded: false 
					}, 
				worksheet: ['Federal\\Income\\Sch C - Business'],
				worksheetSections: ['General', 'Incoem and Cost of Goods Sold', 'Expenses', 'Auto Information, Depreciation and Listed Property Questions', 'Property and Cost Depletion', 'Business Use of Home (Form 8829)', 'Other Expenses'],
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.BUSINESS_INCOME, 
				entryTitle: STR.ENTRY_EXP_SCHEDC,
			},
			{ 
				title: STR.RENTAL_INCOME, 
				entryType: 'Income',
				key: 'dashCard-2-5', 
				formName: REDUX.RENTAL_INCOME, 
				statusId: 1, 
				route: NAV.RENTAL_INCOME, 
				entryIconInactive: IMG.ENTRY_OWNED_RENTAL_PROPERTY_SCHEDULE_E_INACTIVE, 
				dashBoardIcon: IMG.OWNED_RENTAL_PROPERTY_SCHEDULE_E, 
				showBulkUpload: false, 
				showBulkEntityUpload: true, 
				bulkUpload: 
					{ 
						title: STR.RENTAL_INCOME_BULK_UPLOAD_TITLE, 
						subTitle: STR.RENTAL_INCOME_BULK_UPLOAD_SUBTITLE, 
						sectionName: 'scheduleE1099Uploads', 
						uploadList: [], 
						expanded: false, 
						buttonState: 'active', 
						isLoaded: false 
					}, 
				worksheet: ['Federal\\Income\\Sch E, p 1 - Rent and Royalty'], 
				worksheetSections: ['General', 'Income', 'Expenses', 'Auto Information, Depreciation, and Listed Property Questions', 'Depreciation and Amortization (Form 4562)'],
				notes: [], 
				isRequired: false, 
				isRecommended: false, 
				sectionHeader: H1s.RENTAL_INCOME, 
				entryTitle: STR.ENTRY_EXP_SCHEDE ,
			},
			{ 
				title: STR.FARM_INCOME, 
				entryType: 'Income',
				key: 'dashCard-2-6', 
				formName: REDUX.FARM_INCOME, 
				statusId: 1, 
				route: NAV.FARM_INCOME, 
				entryIconInactive: IMG.ENTRY_OWNED_A_FARM_SCHEDULE_F_INACTIVE, 
				dashBoardIcon: IMG.OWNED_A_FARM_SCHEDULE_F, 
				showBulkUpload: false, 
				showBulkEntityUpload: true, 
				bulkUpload: 
					{ 
						title: STR.FARM_INCOME_BULK_UPLOAD_TITLE, 
						subTitle: STR.FARM_INCOME_BULK_UPLOAD_SUBTITLE, 
						sectionName: 'scheduleFFarmDetailsUploads', 
						uploadList: [], 
						expanded: false, 
						buttonState: 'active', 
						isLoaded: false 
					}, 
				worksheet: ['Federal\\Income\\Sch F / 4835 - Farm'], 
				worksheetSections: ['General', 'Income', 'Supporting Information', 'Expenses', 'Auto Information, Depreciation, and Listed Property Questions', 'Depreciation and Amortization (Form 4562)'],
				notes: [], 
				isRequired: false, 
				isRecommended: false, 
				sectionHeader: H1s.FARM_INCOME, 
				entryTitle: STR.ENTRY_EXP_SCHEDF,
			},
			{ 
				title: STR.PASSTHROUGH_INCOME, 
				entryType: 'Income',
				key: 'dashCard-2-7',
				formName: REDUX.PASSTHRU_INCOME, 
				statusId: 1, 
				route: NAV.PASSTHROUGH_INCOME, 
				entryIconInactive: IMG.ENTRY_RECEIVED_A_K1_INACTIVE, 
				dashBoardIcon: IMG.RECEIVED_A_K1, 
				showBulkUpload: true, 
				bulkUpload: 
					{ 
						title: STR.PASSTHROUGH_INCOME_BULK_UPLOAD_TITLE, 
						subTitle: STR.PASSTHROUGH_INCOME_BULK_UPLOAD_SUBTITLE, 
						sectionName: 'k1099Uploads', 
						uploadList: [], 
						expanded: false, 
						buttonState: 'active', 
						isLoaded: false 
					}, 
				worksheet: ['Federal\\Income\\Sch E, p 2 - Fiduciary Passthrough (K-1 1041)'],
				worksheetSections: ['General', 'Activity', 'Interest and Dividends', 'Other Income and Adjustments', 'Depreciation and Amortization (Form 4562)'],
				notes: [], 
				isRequired: false, 
				isRecommended: false, 
				sectionHeader: H1s.PASSTHRU_INCOME, 
				entryTitle: STR.ENTRY_EXP_K1,
				statusDefault: STATUS.RECOMMENDED, 
			},
			{ 
				title: STR.MISC_INCOME, 
				entryType: 'Income',
				key: 'dashCard-2-8', 
				formName: REDUX.MISC_INCOME, 
				statusId: 1, 
				route: NAV.MISCELLANEOUS_INCOME, 
				entryIconInactive: IMG.ENTRY_HAD_ANOTHER_SOURCE_OF_INCOME_INACTIVE, 
				dashBoardIcon: IMG.HAD_ANOTHER_SOURCE_OF_INCOME, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Income\\Other Income'], 
				worksheetSections: ['Miscellaneous Information (IRS 1099-MISC)', 'Nonenmployee Compensation (IRS 1099-NEC)', 'Certain Government Payments (IRS 1099-G', 'Payment C ard and Third Party Network Transactions (IRS 1099-K)', 'Refunds of State and Local Income Taxes - Itemized Information', 'Refunds of State and Local Income Taxes - Detail', 'Alimony received', 'Gambling Winnings (IRS W-2G)', 'Form 1040 Income Adjustments'],
				notes: [], 
				isRequired: false, 
				isRecommended: false, 
				sectionHeader: H1s.MISC_INCOME, 
				entryTitle: STR.ENTRY_EXP_MISC_INCOME, 
				uploadSectionName: 'miscIncomeGeneralUploads' ,
				statusDefault: STATUS.RECOMMENDED,
			}, //'Federal\\General\\Basic Data'
			{ 
				title: STR.FOREIGN_INCOME, 
				entryType: 'Income',
				key: 'dashCard-2-9', 
				formName: REDUX.FOREIGN_INCOME, 
				statusId: 1, 
				route: NAV.FOREIGN_INCOME, 
				entryIconInactive: IMG.ENTRY_EARNED_FOREIGN_INCOME, 
				dashBoardIcon: IMG.FOREIGN_INCOME_DASHBOARD, 
				showBulkUpload: false, 
				notes: [], 
				isRequired: false, 
				isRecommended: false, 
				sectionHeader: H1s.FOREIGN_INCOME, 
				entryTitle: STR.ENTRY_EXP_FOREIGN_INCOME, 
				uploadSectionName: 'foreignIncomeGeneralUploads', 
				worksheet: ['Federal\\Foreign\\2555 - Foreign Earned Income Exclusion'], 
				worksheetSection: ['Bona Fide Residence Test'],
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.RETIREMENT_CONTRIBUTIONS, 
				entryType: 'Expenses',
				key: 'dashCard-3-1', 
				formName: REDUX.RETIREMENT_CONTRIBUTIONS, 
				statusId: 1, 
				route: NAV.RETIREMENT_CONTRIBUTIONS, 
				entryIconInactive: IMG.ENTRY_CONTRIBUTED_TO_A_RETIREMENT_ACCOUNT_INACTIVE, 
				dashBoardIcon: IMG.CONTRIBUTED_TO_A_RETIREMENT_ACCOUNT, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Deductions\\Keogh, SEP and SIMPLE Plans', 'Federal\\Income\\IRAs, Pensions and Annuities (1099-R)',], 
				worksheetSection: 'IRA Information', 
				notes: [], 
				isRequired: false, 
				isRecommended: false, 
				sectionHeader: H1s.RETIREMENT_CONTRIBUTIONS, 
				entryTitle: STR.ENTRY_EXP_RETIREMENT_CONTRIBUTION, 
				uploadSectionName: 'retirementContributionsGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},//'Federal\\Schedule 1 - Additional Income and Adjustments to Income\\Government'		
			{ 
				title: STR.MEDICAL_EXPENSES, 
				entryType: 'Expenses',
				key: 'dashCard-3-2', 
				formName: REDUX.MEDICAL_EXPENSES, 
				statusId: 1, 
				route: NAV.MEDICALEXPENSES, 
				entryIconInactive: IMG.ENTRY_PAID_MEDICAL_EXPENSES_INACTIVE, 
				dashBoardIcon: IMG.PAID_MEDICAL_EXPENSES, 
				showBulkUpload: true, 
				bulkUpload: 
					{ 
						title: STR.MEDICAL_EXPENSE_BULK_UPLOAD_TITLE, 
						subTitle: STR.MEDICAL_EXPENSE_BULK_UPLOAD_SUBTITLE, 
						sectionName: 'medicalExpense1099Uploads', 
						uploadList: [], 
						expanded: false, 
						buttonState: 'active', 
						isLoaded: false 
					}, 
				worksheet: ['Federal\\Deductions\\Sch A - Itemized Deductions'], 
				worksheetSections: ['Other Medical Expenses', 'Medical and Dental Expenses'], 
				notes: [], 
				isRequired: false, 
				isRecommended: false, 
				sectionHeader: H1s.MEDICAL_EXPENSES, 
				entryTitle: STR.ENTRY_EXP_MEDICAL_EXPENSES,
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.PROPERTY_STATE_TAXES, 
				entryType: 'Expenses',
				key: 'dashCard-3-3', 
				formName: REDUX.PROPERTY_STATE_TAXES, 
				statusId: 1, 
				route: NAV.PROPERTYANDOTHERSTATETAXES, 
				entryIconInactive: IMG.ENTRY_PAID_PROPERTY_TAX_INACTIVE, 
				dashBoardIcon: IMG.PAID_PROPERTY_TAX, 
				showBulkUpload: false, 
				worksheetSection: 'Taxes Paid', 
				notes: [], 
				isRequired: false, 
				isRecommended: false, 
				sectionHeader: H1s.PROPERTY_STATE_TAXES, 
				entryTitle: STR.ENTRY_EXP_PROPERTY_TAX, 
				uploadSectionName: 'propertyTaxGeneralUploads', 
				worksheet: ['Federal\\Deductions\\Sch A - Itemized Deductions'], 
				worksheetSections: ['Other Taxes Paid', 'Taxes Paid', 'State and Local Sales Tax Information'],
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.MORTGAGE_INVESTMENT, 
				entryType: 'Expenses',
				key: 'dashCard-3-4', 
				formName: REDUX.MORTGAGE_INVESTMENT, 
				statusId: 1, 
				route: NAV.MORTGAGE_INVESTMENT_INTEREST, 
				entryIconInactive: IMG.ENTRY_PAID_MORTGAGE_OR_OTHER_INTERESTS_INACTIVE, 
				dashBoardIcon: IMG.PAID_MORTGAGE_OR_OTHER_INTERESTS, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Deductions\\Sch A - Itemized Deductions'], 
				worksheetSection: 'Mortgage Interest', 
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.MORTGAGE_INVESTMENT, 
				entryTitle: STR.ENTRY_EXP_MORTGAGE_INTEREST, 
				uploadSectionName: 'mortgageInterestGeneralUploads', 
				worksheetSections: ['Investment and Other Interest', 'Other Home Mortgage Interest Paid', 'Mortgage Interest (IRS 1098-MIS)', 'Home Mortgage Interest Paid to a Financial Institution and Deductible Points'],
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.CONTRIBUTIONS, 
				entryType: 'Expenses',
				key: 'dashCard-3-5', 
				formName: REDUX.CONTRIBUTIONS, 
				statusId: 1, 
				route: NAV.CHARITABLE_CONTRIBUTIONS, 
				entryIconInactive: IMG.ENTRY_SUPPORTED_CHARITABLE_ORGANIZATIONS_INACTIVE, 
				dashBoardIcon: IMG.SUPPORTED_CHARITABLE_ORGANIZATIONS, 
				bulkUpload: 
					{ 
						title: STR.CHARITY_CONTRIBUTIONS_BULK_UPLOAD_TITLE, 
						subTitle: STR.CHARITY_CONTRIBUTIONS_BULK_UPLOAD_SUBTITLE, 
						sectionName: 'charity1099Uploads', 
						uploadList: [], 
						expanded: false, 
						buttonState: 'active', 
						isLoaded: false 
					}, 
				showBulkUpload: true, 
				worksheet: ['Federal\\Deductions\\Sch A - Itemized Deductions', 'Federal\\Deductions\\8283 - Noncash Charitable Contributions (1098-C)'],
				worksheetSections: ['Medical and Dental Expenses', 'Other Medical Expenses', 'Taxes Paid', 'Other Taxes Paid', 'State and Local Sales Tax Information', 'Investments Interest Expense Deduction', 'Contributions', 'Business Use of Home (Form 8829)', 'Miscellaneous Deductions'],
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.CHARITABLE_CONTRIBUTIONS, 
				entryTitle: STR.ENTRY_EXP_CHARITABLE,
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.DEPENDENT_CARE, 
				entryType: 'Expenses',
				key: 'dashCard-3-6', 
				formName: REDUX.DEPENDENT_CARE, 
				statusId: 1, 
				route: NAV.CHILDCARE, 
				entryIconInactive: IMG.ENTRY_PAID_FOR_CHILD_DEPENDENT_CARE_INACTIVE, 
				dashBoardIcon: IMG.PAID_FOR_CHILD_DEPENDENT_CARE, 
				worksheet: ['Federal\\Credits\\2441 - Child and Dependent Care Expenses'], 
				worksheetSections: ['General', 'Persons or Organizations Who Provided the Care', 'Qualifying Persons'],
				showBulkUpload: false,
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.DEPENDENT_CARE, 
				entryTitle: STR.ENTRY_EXP_CHILD_CARE, 
				uploadSectionName: 'childCareGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.HIGHER_EDUCATION, 
				entryType: 'Expenses',
				key: 'dashCard-3-7', 
				formName: REDUX.HIGHER_EDUCATION, 
				statusId: 1, 
				route: NAV.TUITION, 
				entryIconInactive: IMG.ENTRY_PAID_HIGHER_EDUCATION_INACTIVE,
				dashBoardIcon: IMG.PAID_HIGHER_EDUCATION, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Credits\\8863', 'Federal\\Income\\Payments from Qualified Education'], 
				worksheetSections: ['Tution Statement (IRS 1098-T)', 'Payments from Qualified Education Programs (IRS 1099-Q)'],
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.HIGHER_EDUCATION, 
				entryTitle: STR.ENTRY_EXP_HIGHER_EDUCATION, 
				uploadSectionName: 'higherEducationGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.ENERGY_EFFICIENT, 
				entryType: 'Expenses',
				key: 'dashCard-3-8', 
				formName: REDUX.ENERGY_EFFICIENT, 
				statusId: 1, 
				route: NAV.VEHICLEENERGY, 
				entryIconInactive: IMG.ENTRY_MADE_AN_ENERGY_EFFICIENT_PURCHASE_INACTIVE, 
				dashBoardIcon: IMG.MADE_AN_ENERGY_EFFICIENT_PURCHASE,
				worksheet: ['Federal\\Credits\\5695 - Residential Energy Efficient Property Credit'],
				worksheetSections: ['General'],
				showBulkUpload: false, 
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.ENERGY_EFFICIENT, 
				entryTitle: STR.ENTRY_EXP_ENERGY_EFFICIENT, 
				uploadSectionName: 'energyEfficientGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.HOUSEHOLD_EMPLOYMENT, 
				entryType: 'Expenses',
				key: 'dashCard-3-9', 
				formName: REDUX.HOUSEHOLD_EMPLOYMENT, 
				statusId: 1, route: NAV.HOUSEHOLDEMPLOYMENTTAXES, 
				entryIconInactive: IMG.ENTRY_PAID_HOUSEHOLD_EMPLOYEES_INACTIVE, 
				dashBoardIcon: IMG.PAID_HOUSEHOLD_EMPLOYEES, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Taxes\\Sch H - Household Employment Taxes'], 
				worksheetSections: ['General', 'Social Security, Medicare and Income Taxes', 'Federal Unemployment (FUTA) Tax'],
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.HOUSEHOLD_EMPLOYMENT, 
				entryTitle: STR.ENTRY_EXP_HOUSEHOLD, 
				uploadSectionName: 'householdEmploymentGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
			{ 
				title: STR.OTHER_DEDUCTIONS, 
				entryType: 'Expenses',
				key: 'dashCard-3-91', 
				formName: REDUX.OTHER_DEDUCTIONS, 
				statusId: 1, 
				route: NAV.OTHERDEDUCTIONS, 
				entryIconInactive: IMG.ENTRY_OTHER_DEDUCTIONS_INACTIVE, 
				dashBoardIcon: IMG.OTHER_DEDUCTIONS, 
				showBulkUpload: false, 
				worksheet: ['Federal\\Deductions\\Other Adjustments', 'Federal\\Deductions\\8889 - Health Savings Accounts', 'Federal\\Deductions\\Distributions', 'Federal\\Deductions\\2106', 'Federal\\Deductions\\Student Loan Interest Statement'],
				worksheetSections: ['General', 'Alimony Paid', 'Educator Expenses', 'Form 1040 Adjustments', 'Distributions From an HSA or MSA (IRS 1099-SA)', 'Business Income and Expenses', 'Reimbursements', 'Listed Property Information', 'Listed Property Questions', 'Business Use of Home', 'Foreign', 'Ministerial Income'],
				notes: [], 
				isRequired: false, 
				isRecommended: false,
				sectionHeader: H1s.OTHER_DEDUCTIONS, 
				entryTitle: STR.ENTRY_EXP_OTHER_DEDUCTIONS, 
				uploadSectionName: 'otherDeductionsGeneralUploads',
				statusDefault: STATUS.RECOMMENDED,
			},
		]
	},
	{
		title: STR.OPPORTUNITY_SERVICES,
		entryType: 'Services',
		cards: [
			{ 
				title: STR.INVESTMENT_PLANNING, 
				entryType: 'Services', 
				key: 'opportunities-1', 
				formName: '', 
				statusId: 1, 
				route: NAV.ABOUT_YOU, 
				entryIconInactive: IMG.ENTRY_INVESTMENT_PLANNING_INACTIVE, 
				showBulkUpload: false, 
				isRequired: false, 
				entryTitle: STR.ENTRY_EXP_INVESTMENT_PLANNING 
			},
			{ 
				title: STR.FINANCIAL_PLANNING, 
				entryType: 'Services', 
				key: 'opportunities-4', 
				formName: '', 
				statusId: 1, 
				route: NAV.ABOUT_YOU, 
				entryIconInactive: IMG.ENTRY_TAX_AND_FINANCIAL_PLANNING_INACTIVE, 
				showBulkUpload: false, 
				isRequired: false, 
				entryTitle: STR.ENTRY_EXP_FINANCIAL_PLANNING 
			},
			{ 
				title: STR.INSURANCE_PLANNING, 
				entryType: 'Services', 
				key: 'opportunities-3', 
				statusId: 1, 
				formName: '', 
				route: NAV.ABOUT_YOU, 
				entryIconInactive: IMG.ENTRY_INSURANCE_PLANNING_INACTIVE, 
				showBulkUpload: false, 
				isRequired: false, 
				entryTitle: STR.ENTRY_EXP_INSURANCE_PLANNING 
			},
			{ 
				title: STR.OWNER_TRANSITION, 
				entryType: 'Services', 
				key: 'opportunities-2', 
				formName: '', 
				statusId: 1, 
				route: NAV.ABOUT_YOU, 
				entryIconInactive: IMG.ENTRY_OWNER_TRANSITION_SERVICES_INACTIVE, 
				showBulkUpload: false, 
				isRequired: false, 
				entryTitle: STR.ENTRY_EXP_OWNER_TRANSITION 
			},
		]
	},
];

const dashboardCards = dashboard.flatMap((item) => item.cards);

export {
	dashboard,
	dashboardCards,
};