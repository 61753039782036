//Globals.  This is Global state related to the person who is using the app. Form data should NOT be G_
export const IS_AUTHENTICATED = 'G_isAuthenticated';
export const IS_CLIENT = 'G_isClient';
export const IS_PRACTITIONER = 'G_isPractitioner';
export const IS_ADMIN = 'G_isAdmin';
export const AUTH_USER_EMAIL = 'G_authUserEmail';
export const SCREEN_SIZE = 'G_screenSize';
export const TOOLBAR_TITLE = 'G_toolbarTitle';
export const SHOW_BULK_UPLOAD_CONTROL = 'G_showBulkUpload';
export const CUSTOM_DIALOG_TITLE = 'G_customDialogTitle';
export const CUSTOM_DIALOG_MSG = 'G_customDialogMsg';
export const HIDE_CUSTOM_DIALOG_CLOSE_BUTTON = 'G_hideCustomDialogCloseButton';
export const SHOW_CUSTOM_DIALOG = 'G_showCustomDialog';
export const IS_MULTIPLE_UPLOAD = 'isMultipleUpload';
export const DASHBOARD_SORT_REQ = 'G_dashboardSortRequirements';
export const DASHBOARD = 'G_dashboard';
export const PROGRESS_VISIBLE = 'G_isProgressVisible';
export const PROGRESS_TEXT = 'G_progressText';
export const CLA_TOKEN = 'G_claToken';
export const YEAR = 'G_year';
export const IS_VALID_1040_CLIENT = 'G_isValid1040Client';
export const CLIENT_PROFILE = 'G_clientProfile';
export const FORM_BUTTON_MENU = 'G_showFormButtonMenu';
export const HAS_SKIP_ENTRY_STEP = 'G_hasSkipEntryStep';
export const UPLOAD_PROGRESS_VISIBLE = 'G_isUploadProgressVisible';
export const LOCKING_PERMISSON = 'G_hasLockingPermission';

// Data Specific details.  Can change by return being viewed. These are reset when a new return is selected
export const ACTIVE_RETURN = 'activeReturn';
export const ACTIVE_CLIENT_ID = 'activeClientId';
export const LOCK_FORMS = 'lockForms';
export const CURRENT_CARD_KEY = 'currentKeyCard';
export const CURRENT_CARD_STATUS = 'currentCardStatus';
// export const PRIOR_YEAR_DATA = 'G_PriorYearData';
export const PRIOR_YEAR_DATA = 'priorYearData';
export const RELOAD_PRIOR_DATA = 'reloadPriorData';
export const PRIOR_YEAR_DETAILS = 'G_PriorYearDetails';
export const CURRENT_CARD = 'currentCard';
export const DASHBOARD_FILTER_STATUS = 'dashboardFilterStatus';
export const ORGANIZER_ID = 'organizerId';
export const IS_FUTURE_YEAR = 'isFutureYear';
export const TAX_RETURN_STATUS = 'taxReturnStatus';
export const BANNER_DISMISSED = 'bannerDismissed';
export const IS_TERMS_AGREED = 'isTermsAgreed';

// Data Specific details.  Can change by return being viewed. These are reset when a new return is selected
export const DASHBOARD_FILTERED = 'dashboardFiltered';
export const UPLOAD_LIST = 'uploadList';
export const FILE_CATEGORY_COUNT = 'fileCategoryCount';

export const ENTRY_INTERVIEW = 'entryInterview';
export const ABOUT_YOU = 'aboutYou';
export const DEPENDENTS = 'dependents';
export const ABOUT_YOUR_YEAR = 'aboutYourYear';
export const REFUND_TAX_PAYMENT = 'refundTaxPayment';
export const TAX_PAYMENTS = 'taxPayments';
export const STATE_TAX = 'stateTax';
export const FOREIGN_ASSETS = 'foreignAssets';

export const WAGES = 'wages';
export const INVESTMENT_INCOME = 'investmentIncome';
export const RETIREMENT_INCOME = 'socialSercurityRetirement';
export const MISC_INCOME = 'miscellaneousIncome';
export const BUSINESS_INCOME = 'businessIncome';
export const RENTAL_INCOME = 'rentalIncome';
export const FARM_INCOME = 'farmIncome';
export const PASSTHRU_INCOME = 'passthroughIncome';
export const VEHICLE = 'vehicle';
export const FOREIGN_INCOME = 'foreignIncome';
export const PROVIDER = 'provider';
export const DEPENDENT_CARE = 'dependentCare';

export const MEDICAL_EXPENSES = 'medicalExpenses';
export const PROPERTY_STATE_TAXES = 'propertyAndOtherStateTaxes';
export const MORTGAGE_INVESTMENT = 'mortgageInvestmentInterest';
export const RETIREMENT_CONTRIBUTIONS = 'retirementContributions';
export const CONTRIBUTIONS = 'contributions';
export const HIGHER_EDUCATION = 'higherEducation';
export const ENERGY_EFFICIENT = 'energyEfficient';
export const HOUSEHOLD_EMPLOYMENT = 'householdEmployment';
export const OTHER_DEDUCTIONS = 'otherDeductions';

export const PRACTITIONERS_DASHBOARD = 'pract-dash';
export const ADMIN_DASHBOARD = 'admin-dash';

export const COMPLETED_ENTRY_STEP = 'completedEntryStep';
export const FORM_NOTES = 'formNotes';
export const ADD_CLIENT_FORM = 'addClientForm';
export const IS_CURRENT_FORM_NA = 'isCurrentFormNa';

export const DOCUMENT_MANAGER = 'doc-manager';

export const SELECTED_CLIENTS = 'selected-clients';
export const CLIENT_MARITAL_STATUS = 'clientMaritalStatus';

export const IS_SAVE_SUCCESS = 'saveSuccess';
export const LAST_SAVE_FORM_TIME = 'lastFormSaveTime';

// organizerExport Error Codes Page 
export const SET_ORG_EXPORT_ERRORS = 'setOrgExportErrorsForErrorCodePage';

//upload warning dialog
export const UPLOAD_WARNING_VISIBLE = 'uploadWarningVisible';
export const DUPLICATE_UPLOAD_FILES = 'duplicateUploadFiles';
export const DUPLICATE_UPLOAD_FILES_SKIPPED = 'duplicateUploadFilesSkipped';
export const UPLOADS_PROPS = 'uploadsProps';

export const DASHBOARD_ID = 'dashboardId';
export const NAVBAR_CARDS = 'navBarCards';
export const TAXPAYER_EMAIL = 'taxpayerEmail';
export const SPOUSE_EMAIL = 'spouseEmail';
export const IS_SUBMITTED_TO_CLA = 'isSubmittedToCLA';
export const TAXPAYER_NAME = 'taxpayerName';
export const TAXPAYER_SPOUSE_NAME = 'taxpayerSpouseName';
export const PRIOR_RETURN_ID = 'priorYearReturnId';
export const TAXPAYER_INITIALS = 'taxPayerInitials';
export const HAS_MULTIPLE_RETURNS = 'hasMultipleReturns';
export const SELECTED_ORGANIZER = 'selectedOrganizer';
export const ENTRY_MODAL_OPEN = 'entryModalOpen';
export const AUTHORIZED_IDS = 'authorizedIds';
export const CLIENT_ORGANIZERS = 'clientOrganizers';
