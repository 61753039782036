// common modules
import React, { useEffect, useState, useRef } from 'react';
import { useToggle } from "@react-md/utils";
// MUI imports
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  DataGridPro, gridClasses
} from '@mui/x-data-grid-pro';
//MUI pagination
import CustomToolbar from '@components/organizerList/customToolbar';
import CustomNoResultsOverlay from './customNoResultsOverlay';
// custom modules
import api from '@utilities/claApi';
import * as NAV from '@utilities/constants/navigation';
import Breadcrumb from '@components/Breadcrumb.js';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { MAX_PREFETCH_LIMIT, DEFAULT_FILTER_QUERY, DEFAULT_SORT_QUERY, OPERATORS_MAP, DEFAULT_PAGE_LIMIT, SORT_TO_DATA_MAP, FILTER_TO_DATA_MAP } from '@utilities/constants/manageNotification.js';
import useServerSideMUI from '@utilities/hooks/useServerSideMUI';
import CreateResourceDialog from '@components/restfulTable/CreateResourceDialog.js';
import DeleteResourceDialog from '@components/restfulTable/DeleteResourceDialog.js';
import EditResourceDialog from '@components/restfulTable/EditResourceDialog.js';
import moment from 'moment/moment';
import { MM_DD_YY_HH_MM_A } from '@utilities/constants/dateFormats';

function ManageNotifications(props) {
  const {
    ACTION,
    dispatch,
  } = usePageFramework();
  const resourceName = 'Scheduled Notifications';
  const resourceUri = '/schedulednotifications';
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  const [isLoaded, enable, disable] = useToggle(false);
  const [paginatedLoad, setPaginatedLoad] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [showCreateDialog, enableCreateDialog, disableCreateDialog] = useToggle(false);
  const [showDeleteDialog, enableDeleteDialog, disableDeleteDialog] = useToggle(false);
  const [showEditDialog, enableEditDialog, disableEditDialog] = useToggle(false);
  const [showDialog, enableDialog, disableDialog] = useToggle(false);
  const [selected, setSelected] = useState({});
  const previousOrganizerQuery = useRef(null);
  const [prefetchedData, setPrefetchedData] = useState([]);
  const [data, setData] = useState([]);
  const payload = {
    defaultFilter: DEFAULT_FILTER_QUERY,
    defaultSort: DEFAULT_SORT_QUERY,
    defaultPageSize: DEFAULT_PAGE_LIMIT,
    filterDataMap: FILTER_TO_DATA_MAP,
    sortDataMap: SORT_TO_DATA_MAP,
    operatorsMap: OPERATORS_MAP,
    defaultPrefetchSize: MAX_PREFETCH_LIMIT,
  };
  const {
    handleFilterModelChange,
    handleSortModelChange,
    handlePageModelChange,
    handlePageSizeChange,
    currentPageNumber,
    filterQueryParameters,
    filterMethodParameters,
    sortQueryParameters,
    pageSize,
    prefetchSize,
    currentFilterModel,
    currentSortModel,
  } = useServerSideMUI(payload);
  const filterHighlight = currentFilterModel?.items?.[0]?.value ? 'filterButtonHighlight' : 'filterButton';
  const intervalOption = [{ label: 'Daily', value: 'daily' }, { label: 'Weekly', value: 'weekly' }, { label: 'Bi-Weekly', value: 'bi-weekly' }];
  const typeOptions = [
    {
      label: 'BEGIN_INDIVIDUAL_TAX_PREPARATION',
      value: 'BEGIN_INDIVIDUAL_TAX_PREPARATION'
    },
    {
      label: 'CONTINUE_1040_EXCHANGE_SUBMISSION',
      value: 'CONTINUE_1040_EXCHANGE_SUBMISSION'
    },
    {
      label: 'COMMUNICATION_OF_1040_EXCHANGE_STATUS_TO_CRL',
      value: 'COMMUNICATION_OF_1040_EXCHANGE_STATUS_TO_CRL'
    }
  ];
  const columnsGrid = [
    { field: 'title', headerName: 'Title', type: 'text', width: 150, editable: false, canEdit: true, required: true, },
    {
      field: 'description',
      headerName: 'Description',
      type: 'text',
      width: 150,
      editable: false,
      canEdit: true,
      required: true,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      type: 'datetimepicker',
      width: 150,
      editable: false,
      canEdit: true,
      required: true,
      renderCell: (params) => {
        let sDate = moment(params.value).format(MM_DD_YY_HH_MM_A)
        return <span>{sDate}</span>
      }
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      type: 'datetimepicker',
      width: 150,
      editable: false,
      canEdit: true,
      required: true,
      renderCell: (params) => {
        let eDate = moment(params.value).format(MM_DD_YY_HH_MM_A)
        return <span>{eDate}</span>
      }
    }, {
      field: 'interval',
      headerName: 'Interval',
      type: 'dropDown',
      width: 150,
      editable: false,
      canEdit: true,
      required: true
    }, {
      field: 'type',
      headerName: 'Type',
      type: 'dropDown',
      width: 150,
      editable: false,
      canEdit: true,
      required: true
    }, {
      field: 'status',
      headerName: 'Status',
      type: 'text',
      width: 150,
      editable: false,
      canEdit: false,
    },
    {
      field: 'message',
      headerName: 'Message',
      type: 'text',
      width: 150,
      editable: false,
      canEdit: false,
    },
    {
      field: 'lastSent',
      headerName: 'Last Sent',
      type: 'text',
      width: 150,
      editable: false,
      canEdit: false,
      renderCell: (params) => {
        let eDate = moment(params.value).format(MM_DD_YY_HH_MM_A)
        return <span>{params.value ? eDate : params.value}</span>
      }
    }, {
      field: 'nextSend',
      headerName: 'Next Send',
      type: 'text',
      width: 150,
      editable: false,
      canEdit: false,
      renderCell: (params) => {
        if (!params.value) {
          return 'N/A';
        }
        let sDate = moment(params.value).format(MM_DD_YY_HH_MM_A);
        return <span>{sDate}</span>
      }
    }, {
      field: 'count',
      headerName: 'Count',
      type: 'number',
      width: 150,
      editable: false,
      filterable: false,
      canEdit: false,

    }, {
      field: 'active',
      headerName: 'Active',
      type: 'boolean',
      width: 150,
      editable: false,
      canEdit: true,
    }, {
      field: 'templateId',
      headerName: 'Template Id',
      type: 'text',
      width: 150,
      editable: false,
      canEdit: true,
      required: true,
    }
    , {
      field: 'actions',
      type: 'actions',
      align: 'left',
      editable: false,
      canEdit: false,
      renderHeader: (params) => {
        return <AddIcon
          onClick={() => {
            enableCreateDialog(true);
          }}
        />
      },
      renderCell: (params) => {
        return (
          <>
            <EditIcon
              onClick={() => {
                setSelected(params.row);
                enableEditDialog();
              }}
            />
            <DeleteIcon
              onClick={() => {
                setSelected(params.row);
                enableDeleteDialog();
              }}
            />
          </>
        )
      }
    }
  ];
  const columns = columnsGrid.filter(col => col.headerName && col.canEdit).map(column => {
    let options = [];
    if (column.type === "dropDown") {
      if (column.field === "type") {
        options = typeOptions;
      } else if (column.field === "interval") {
        options = intervalOption;
      }
    }
    return {
      key: column.field, text: column.headerName, type: column.type, options: options, create: { show: true, required: column.required }, edit: { show: true, required: column.required }
    }
  });

  const navItems = [
    { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
    { to: NAV.MANAGE_NOTIFICATIONS, label: 'Notifications', current: true }
  ];

  const buildOrganizerQuery = (limit, offset, sorting, filterMethod, filtering) => {
    const filterQuery = filtering?.length ? filtering.map(x => `&filter=${x}`).join('') : '';
    const filterMethodQuery = filterMethod ? `&filterMethod=${filterMethod}` : '';
    const sortQuery = sorting && Object.entries(sorting).length ? Object.entries(sorting).map(([param, query]) => `&${param}=${query}`).join('') : '';
    return `${sortQuery}${filterMethodQuery}${filterQuery}&limit=${limit}&offset=${offset}`
  };

  useEffect(() => {
    if (!isLoaded) {
      setPaginatedLoad(true);
      const totalOffset = currentPageNumber * pageSize;
      const prefetchOffset = Math.floor(totalOffset / prefetchSize) * prefetchSize;
      const prefetchPageOffset = totalOffset % prefetchSize
      const organizerQuery = buildOrganizerQuery(prefetchSize, prefetchOffset, sortQueryParameters, filterMethodParameters, filterQueryParameters);
      // If the resulting queries are the same, grab organizer data from the prefetched set
      if (previousOrganizerQuery.current !== null && organizerQuery === previousOrganizerQuery.current) {
        const resultSlice = prefetchedData.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
        setData(resultSlice);
        setPaginatedLoad(false);
        enable();
        return;
      }
      api.get(`/schedulednotifications?${organizerQuery}`).then((response) => {
        return response.data;

      }).then(response => {
        const { results, total } = response;

        const resultSlice = results.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
        setPrefetchedData(results);
        setData(resultSlice);
        setTotalResults(total);
      }).finally(() => {
        enable();
        setPaginatedLoad(false);
      });
    }
  }, [sortQueryParameters, filterMethodParameters, filterQueryParameters, isLoaded, pageSize, prefetchSize, currentPageNumber])

  // create resource
  const createResource = async (resource) => {
    dispatch(ACTION.setProgressText('Notification Creating...'));
    dispatch(ACTION.setProgressVisible(true));
    if (!resource.active) {
      resource.active = false;
    }

    // Convert startDate/endDate to contain timezeones
    resource.startDate = moment(resource.startDate).toDate();
    resource.endDate = moment(resource.endDate).toDate();

    api.post(resourceUri, resource).then((response) => {
      // hide create dialog
      disableCreateDialog();
      dispatch(ACTION.setProgressVisible(false));

      // update resource list
      disable();
    }).catch((error) => {
      console.error(error);
      dispatch(ACTION.setProgressVisible(false));

      dispatch(ACTION.setCustomDialogTitle('An error has occurred when creating the scheduled notification'));
      dispatch(ACTION.setShowCustomDialog(true));
    });
  };

  // update resource
  const updateResource = async (id, update) => {
    dispatch(ACTION.setProgressText('Notification Updating...'));
    dispatch(ACTION.setProgressVisible(true));
    delete update.id;

    // Convert startDate/endDate to contain timezeones
    update.startDate = moment(update.startDate).toDate();
    update.endDate = moment(update.endDate).toDate();

    api.put(`${resourceUri}/${id}`, update).then((response) => {
      // hide edit dialog
      disableEditDialog();
      dispatch(ACTION.setProgressVisible(false));

      // update resource list
      disable();
    }).catch((error) => {
      console.error(error);

      dispatch(ACTION.setProgressVisible(false));

      dispatch(ACTION.setCustomDialogTitle('An error has occurred when editing the scheduled notification'));
      dispatch(ACTION.setShowCustomDialog(true));
    });
  };

  // delete resource
  const deleteResource = async (id) => {
    dispatch(ACTION.setProgressText('Notification Deleting...'));
    dispatch(ACTION.setProgressVisible(true));
    api.delete(`${resourceUri}/${id}`).then((response) => {
    }).catch((error) => {
      console.error(error);

      // Show error as dismissable dialog
      dispatch(ACTION.setCustomDialogTitle('An error has occurred when deleting the scheduled notification'));
      dispatch(ACTION.setShowCustomDialog(true));
    }).finally(() => {
      // hide delete dialog
      disableDeleteDialog();
      dispatch(ACTION.setProgressVisible(false));
      // update resource list
      disable();
    });
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 30 : 6,
      bottom: params.isLastVisible ? 9 : 6,
    };
  }, []);

  // GROWTH: Refactor the DataGridPro component to a generic/reusable component
  return (
    <div className="pracDashboardSize">
      <Breadcrumb items={navItems} />
      <h1>Scheduled Notifications</h1>
      <Box sx={{ height: 'auto', width: '100%' }} data-testid='pracdash-columnheader'>
        <DataGridPro
          loading={paginatedLoad}
          filterMode={'server'}
          disableMultipleColumnsFiltering={false}
          filterModel={currentFilterModel}
          onFilterModelChange={(filterModel) => {
            handleFilterModelChange(filterModel);
            disable();
          }}
          filterDebounceMs={300}
          getRowSpacing={getRowSpacing}
          rows={data}
          columns={columnsGrid}
          pageSizeOptions={[25, 50]}
          columnVisibilityModel={{}}
          disableColumnResize={true}
          disableColumnSelector
          disableMultipleRowSelection={true}
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          paginationMode={'server'}
          rowCount={totalResults}
          onPaginationModelChange={(props) => {
            handlePageSizeChange(props.pageSize);
            handlePageModelChange(props.page);
            disable();
          }}
          sortingMode={'server'}
          paginationModel={{ pageSize: pageSize, page: currentPageNumber }}
          sortModel={currentSortModel}
          onSortModelChange={(sortModel) => {
            handleSortModelChange(sortModel);
            disable();
          }}
          localeText={{ toolbarFilters: "" }}
          autoHeight={true}
          rowHeight={98}
          pagination
          disableVirtualization
          getDetailPanelHeight={({ row }) => 'auto'}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: CustomNoResultsOverlay,
            noResultsOverlay: CustomNoResultsOverlay
          }}

          slotProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
            toolbar: {
              setFilterButtonEl,
              filterHighlight
            },

          }}
          sx={{
            [`& .MuiDataGrid-columnsPanel > div:first-child`]: { display: "none" },
            [`& .${gridClasses.row}`]: {
              bgcolor: '#ffffff',
              borderRadius: 1,
              margin: 4,
              width: '91%',
              border: 0.25,
              borderColor: '#E5E5E5'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-columnHeadersInner': {
              margin: 4
            },
            '& .MuiDataGrid-detailPanelToggleCell': {
              padding: 3
            },
            '& .MuiDataGrid-detailPanel': {
              marginLeft: 4,
              marginRight: 5,
              width: '95.2%',
            },
            '& .MuiDataGrid-columnSeparator--sideRight': {
              display: 'none'
            },
          }}
        />
      </Box>
      <CreateResourceDialog visible={showCreateDialog} resourceName={resourceName} resourceProps={columns} onCancel={disableCreateDialog} onConfirm={createResource} />
      <DeleteResourceDialog visible={showDeleteDialog} resourceName={resourceName} resourceProps={columns} onCancel={disableDeleteDialog} onConfirm={() => deleteResource(selected.id)} />
      <EditResourceDialog visible={showEditDialog} selected={selected} resourceName={resourceName} resourceProps={columns} onCancel={disableEditDialog} onConfirm={updateResource} />
    </div>
  );
}

export default ManageNotifications;