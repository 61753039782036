import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { FormRenderer, BuildFormGroups, Collapse } from 'cla-formrenderer';

import './formRenderer.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
// import { triggeredEvent, sectionFieldLogic } from '@components/formRenderer/triggers';
import { mergeFormData } from '@utilities/formData/formDataMerger';
import * as LOOKUPS from '@utilities/constants/lookupInfo';
// import Collapse from './collapsibleForm/index';
// import BuildFormGroups from './buildGroups';
import StateEntity from '@views/personalAndOther/stateTaxEntity';
import NewVehicleEntity from '@views/income/newVehicleEntity';
import NewProviderEntity from '@views/deductions/newProviderEntity';
// import { filerPYByControl } from '../../utils/axcessTaxPull';
import populatePriorDataModule from '@utilities/populatePriorData/populatePriorData';
import prePopulateLineItems from '@utilities/populatePriorData/prePopulateLineItems';
import { filterPYFromIdentifiers } from '@utilities/populatePriorData/parsePriorEntityData';
import populateSections from '@utilities/populatePriorData/populateSections';
// import { calculateTotalFooter } from '@utilities/populatePriorData/calculateTotalFooter';
import { getPostPopulateFunction } from '@utilities/populatePriorData/postPopulate';
import getYear from '@utilities/helpers/getYear';
import ErrorMessage from '@components/errorMessage';

//exportRenderer new components
import CollapseExport from '@components/organizerExport/organizerRenderer/components/exportRenderer/collapsibleForm';
import BuildFormGroupsExport from '@components/organizerExport/organizerRenderer/components/exportRenderer/buildGroups';
import ExportRenderer from '@components/organizerExport/organizerRenderer/components/exportRenderer';
import { dashboard } from "@utilities/constants/dashboard";
import { isExportSectionFiltered, checkValuedFields } from '@components/organizerExport/organizerRenderer/components/utils/exportFormSections';
import getFormName from "@utilities/helpers/getFormName";
import fieldDataSetter from "@utilities/helpers/getFieldData";
import { filterGroupLines } from '@utilities/populatePriorData/axcessGroupFilter';
import * as STRING from '@utilities/constants/strings';

/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

function FormRendererHelper(props) {
	const { sections, formName, updateEntity, identifiers, parentFns, passFromEntity, hasLineItemSection, passedPriorData, entityScrollTab, entityIndex,
		updateEntityUniqueValue, setUniqueEntityFieldValue, showSharedEntityDialog, isFormPresent, isFormExpandable, isLineItemSection, parentFormName,
		parentGroup, parentIndex, isScheduleFormSection, triggeredEvent, sectionFieldLogic, currentIndex, isParentEntityNA, isExportForm, defaultFormName, barcodeName, entityFilter } = props;
	const { selectState, screenSize, REDUX, CARDSTATUS, INPUTSTATE, setGlobalFormState, updateStatus, card, setCardsRequired } = usePageFramework();
	const formState = selectState(formName || null);
	const priorYearData = !identifiers || !identifiers.length || identifiers[0].isNew ? null : passedPriorData ? identifiers : selectState(REDUX.PRIOR_YEAR_DATA);
	const inputState = selectState(REDUX.IS_PRACTITIONER) || selectState(REDUX.IS_ADMIN) ? INPUTSTATE.PRACTITIONER_INPUT : INPUTSTATE.USER_INPUT;
	const isFormLocked = selectState(REDUX.LOCK_FORMS);
	const isReloadPriorData = selectState(REDUX.RELOAD_PRIOR_DATA);
	const [isLoaded, setIsLoaded] = useState(false);
	const isFormNA = selectState(REDUX.IS_CURRENT_FORM_NA);
	const [isFormDisabled, setIsFormDisabled] = useState(isFormLocked || isFormNA);
	const [formSections, setFormSections] = useState(sections);
	const [bookmarkTitle, setBookmarkTitle] = useState('');
	const lastDenseRowSection = sections[sections.length - 1];
	let isDenseRowNA = false;
	const [hasLoadingError, setHasLoadingError] = useState(false);
	const [isSorted, setIsSorted] = useState(true);
	const currentYear = selectState(REDUX.YEAR);
	const isEntryInterview = formName === REDUX.ENTRY_INTERVIEW;
	const year = getYear(selectState);
	const dashboardCards = dashboard.flatMap(item => item.cards);
	const modifiedFormName = getFormName(formName, parentFormName).modifiedFormName;
	fieldDataSetter.inputState = inputState;

	useEffect(() => {
		setIsLoaded(false);
		setIsFormDisabled(isParentEntityNA || isFormLocked || isFormNA);
	}, [formState, isFormLocked, isFormNA, parentGroup, currentIndex, isParentEntityNA]);

	useEffect(() => {
		if(isReloadPriorData){
			setIsLoaded(false);
		}
	}, [isReloadPriorData])

	const validatedSections = _.cloneDeep(sections);
	const saveForm = (field, nextTabId, childFormSectionData, denseRowSection, setDenseRowSection) => {
		if (field) {
      if (field.default && field.hasValueChanged) {
        field.inputState = inputState;
      } else {
        field.inputState = field.inputState
      }

			//to handle dropdown that is changing from inputState 1 to 5 when NA is clicked
			if (field.inputState === 5 && field.prior && !field.isPriorEditable) 
			{
				field.inputState = INPUTSTATE.PRIOR_INPUT;
			}

			// fix bug # 66194 - Global 1040 | Fields with hardcoded PY data displays as enabled when the row is set to N/A and can be updated 
			if (field.inputState !== INPUTSTATE.PRIOR_INPUT && field.prior && !field.isPriorEditable && field.type === 'label') 
			{
				field.inputState = INPUTSTATE.PRIOR_INPUT;
			}

			if (field.isEntityName) {
				if (!denseRowSection) updateEntity(field.default, formSections);
				else updateEntity(field.default, denseRowSection);
			}

			// for saving the unique vehicle field values that are specific to the entity 
			if (field.uniqueName && field.default && !field.hasUniqueGroupTrigger) {
				updateEntityUniqueValue({field: field});
			}

			if (field.isSorted) {
				setIsSorted(false);
			}

			if (field.requiredCardsToSet) {
				setCardsRequired(field.requiredCardsToSet, field.isSetRequiredCards);
			}

			const priorYearChanged = field.priorYearValue !== field.default;
			const valued = field.default?.length > 0 && field.default !== ' ' ? true : false;
			const isValueChanged = field.priorYearValue ? priorYearChanged : valued;

			if (card && (card.statusId === CARDSTATUS.NOT_STARTED || card.statusId === CARDSTATUS.REQUIRED) && isValueChanged) {
				updateStatus(CARDSTATUS.IN_PROGRESS);
			}
		}

		if (formName) {
			if (childFormSectionData && childFormSectionData.childFormSections) {
				const { childFormSections, sectionIndex, groupIndex, parentIndex, groupItemIndex } = childFormSectionData;
				let newForm = [];
				if (!denseRowSection) newForm = _.cloneDeep(formSections);
				else newForm = _.cloneDeep(denseRowSection);

				if (groupItemIndex && newForm?.[sectionIndex]?.groups?.[groupItemIndex]?.groupItems?.[parentIndex]?.sections) {
					newForm[sectionIndex].groups[groupItemIndex].groupItems[parentIndex].sections = childFormSections;
				} else if (newForm?.[sectionIndex]?.groups?.[groupIndex]?.entities?.[parentIndex]?.sections) {
					newForm[sectionIndex].groups[groupIndex].entities[parentIndex].sections = childFormSections;
				}

				if (setDenseRowSection && newForm) {
					setDenseRowSection(newForm);
				}

				setFormSections(newForm);
				if (!isExportForm) setGlobalFormState(formName, newForm);
			} else {
				if (!isLineItemSection) {
					if (!isExportForm) setGlobalFormState(formName, formSections);
				}
			}
		}

		if (parentFns) {
			parentFns.saveForm(field, nextTabId, { childFormSections: formSections });
		}

		if (nextTabId) { // This should fix the tabbing issue with textbox!
			setTimeout(() => {
				document.getElementById(nextTabId)?.focus();
			}, 100);
		}
	}

	const triggered = (trigger, event, triggerObj) => {
		const { triggeredSections, triggeredFormSections, triggeredSetFormSections } = triggerObj ? triggerObj : {};
		triggeredEvent(trigger, event,
			{
				sections: triggeredSections || validatedSections,
				formSections: triggeredFormSections || formSections,
				setFormSections: triggeredSetFormSections || setFormSections,
				inputState,
				allFormSections: formSections
			}
		);
	};

	const populateValues = () => {
		if (!validatedSections || isLoaded) return;
		if (hasLoadingError) return;

		// Client data exists
		// Attempt to merge the client's data with the form definition
		if (formState) {
			try {
				const { mergedFormData, hasErrors } = mergeFormData(validatedSections, null, formState, isSorted, isFormLocked);

				if (!hasErrors) {
					if(formName.includes(STRING.PROVIDER_HYPHEN) && mergedFormData?.length > 0){
						mergedFormData?.[0]?.groups?.[8]?.lineItems?.forEach(lineItem => {
							lineItem[0].fieldOptions = mergedFormData[0].groups[8].fields[0].fieldOptions;
						})
					}
					setFormSections(mergedFormData);
					setIsLoaded(true);
				} else {
					setHasLoadingError(true);
				}
			} catch (error) {
				console.error(error);
				setHasLoadingError(true);
			}
			return;
		}

		setFormSections(validatedSections);

		if (!identifiers || !priorYearData) {
			validatedSections?.forEach((section) => {
				section?.groups?.forEach((group) => {
					if (group?.axcessGroup?.pull?.postPopulateFn) {
						const postPopulateFunction = getPostPopulateFunction(
							group.axcessGroup.pull.postPopulateFn
						);
						postPopulateFunction(
							group,
							group.axcessGroup.pull.postPopulateMetadata,
							false,
						);
					}
				});
			});
			setIsLoaded(true);
			if (!isExportForm) setGlobalFormState(formName, validatedSections);
			return;
		}

		if (typeof (identifiers) === 'object' && !Array.isArray(identifiers)) {  // Raw data instead of having to extract from prior data
			populateWithPriorData(validatedSections, identifiers);
			if (formName) {
				if (!isExportForm) setGlobalFormState(formName, validatedSections);
			} else {
				saveForm(null, null, validatedSections);
			}

			setIsLoaded(true);
			return;
		}

		let priorYearDataFiltered = identifiers.reduce(filterPYFromIdentifiers(priorYearData), []); // Filter prior year data based on identifiers provided

		// Filter prior year data based on entity filter
		if (entityFilter) {
			const { type, filters } = entityFilter;
			priorYearDataFiltered = filterGroupLines(priorYearDataFiltered, type, filters);
		}

		// // Associative mapping/matching of data
		validatedSections.forEach((section) => {
			section?.groups?.forEach((group) => {
				if (group.entityIdentifiers) {
					populateSections(priorYearDataFiltered, priorYearData)(group);
				}
			});
		});

		const formContext = {
			currentYear,
		};

		// // Regular mapping of PY data to form definition
		priorYearDataFiltered?.forEach((priorYearDataSheet) => {
			if (priorYearDataSheet) {
				populatePriorDataModule(priorYearData, triggeredEvent).buildFromSheetSection(priorYearDataSheet.data, validatedSections, null, formContext);
			}
		});

		// Post-mapping form data building
		validatedSections.forEach((section) => {
			section?.groups?.forEach((group) => {
				if (group.prePopulate && group.prePopulate.length) {
					prePopulateLineItems(group, priorYearData, formContext);
					group.prePopulate = [];
				}

				if (group?.axcessGroup?.pull?.postPopulateFn) {
					const postPopulateFunction = getPostPopulateFunction(
						group.axcessGroup.pull.postPopulateFn
					);
					postPopulateFunction(
						group,
						group.axcessGroup.pull.postPopulateMetadata,
						false,
					);
				}
			});
		});

		if (formName) {
			if (!isExportForm) setGlobalFormState(formName, validatedSections);
		}

		setIsLoaded(true);
	};

	populateValues();

	const renderEntity = (section, formName) => {
		if (section?.sectionType === "newVehicleEntity") {
			return (
				<NewVehicleEntity
					parentFormName={formName}
					isFormExpandable={section.isFormExpandable}
					isEntityVisible={section.isVisible}
					isLineSection={section.isLineSection}
					lineItem={section.lineItem || 0}
                    isParentEntityNA={section.isEntityNa}
					year={year}
					isExportForm={isExportForm}
				/>
			);
		}
		if (section?.sectionType === "newProviderEntity") {
			return (
				<NewProviderEntity
					parentFormName={formName}
					isFormExpandable={section.isFormExpandable}
					isEntityVisible={section.isVisible}
					isLineSection={section.isLineSection}
					lineItem={section.lineItem || 0}
					isExportForm={isExportForm}
					year={year}
				/>
			);
		}
		if (section?.sectionType === "stateEntity") {
			return <StateEntity 
				isExportForm={isExportForm}	
			/>;
		}
	}

	const Sections = !formSections ? null : formSections.map((section, index) => {
		isDenseRowNA = section?.isDenseRow && parentGroup?.lineItems?.length && parentGroup?.lineItems[parentIndex] ? parentGroup.lineItems[parentIndex][0]?.notApplicable : false;

		if (isEntryInterview) {
			index === currentIndex ? section.isVisible = true : section.isVisible = false;
		}

		if (section?.sectionType) {
			return renderEntity(section, formName);
		}

		if (isExportForm && isExportSectionFiltered(formName)) {
			if (checkValuedFields(section, formSections) === 0) return (null);
		} 

		const isSectionVisible = typeof (section?.isVisible) === 'undefined' ? true : typeof (section?.isVisible) === 'function' ? section?.isVisible() : section?.isVisible;
		let sectionClass = 'normalSection';

		if (!isSectionVisible) return (null);

		if (section?.groups?.length === 0) {
			sectionClass = '';
		}

		const groups = section ? isExportForm ? 
			<BuildFormGroupsExport
				key={`build-form-groups-key-${section.sectionId}`}
				section={section}
				screenSize={screenSize}
				saveForm={saveForm}
				isFormLocked={isFormDisabled}
				triggered={triggered}
				sectionClass={sectionClass}
				sectionIndex={index}
				entityScrollTab={entityScrollTab}
				entityIndex={entityIndex}
				setUniqueEntityFieldValue={setUniqueEntityFieldValue}
				showSharedEntityDialog={showSharedEntityDialog}
				parentGroup={parentGroup}
				parentIndex={parentIndex}
				isDenseRowNA={isDenseRowNA}
				renderEntity={renderEntity}
				sectionFieldLogic={sectionFieldLogic}
				isEntryInterview={isEntryInterview}
				updateEntityUniqueValue={updateEntityUniqueValue}
				formName={formName}
				modifiedFormName={modifiedFormName}
			/> :
			<BuildFormGroups
				key={`build-form-groups-key-${section.sectionId}`}
				section={section}
				screenSize={screenSize}
				saveForm={saveForm}
				isFormLocked={isFormDisabled}
				triggered={triggered}
				sectionClass={sectionClass}
				sectionIndex={index}
				entityScrollTab={entityScrollTab}
				entityIndex={entityIndex}
				setUniqueEntityFieldValue={setUniqueEntityFieldValue}
				showSharedEntityDialog={showSharedEntityDialog}
				parentGroup={parentGroup}
				parentIndex={parentIndex}
				isDenseRowNA={isDenseRowNA}
				renderEntity={renderEntity}
				sectionFieldLogic={sectionFieldLogic}
				isEntryInterview={isEntryInterview}
				updateEntityUniqueValue={updateEntityUniqueValue}
			/> : null;

		return (section ?
			<React.Fragment key={`section-collapse-key-${section.sectionId}`}>
				{ isExportForm ? 
				<CollapseExport
					key={index}
					title={section.title}
					collapseClass={section.collapseClass || null}
					index={index}
					groups={groups}
					allSections={formSections}
					section={section}
					sectionClass={sectionClass}
					fns={{ saveForm, setUniqueEntityFieldValue, showSharedEntityDialog, updateEntityUniqueValue }}
					isFormPresent={isFormPresent}
					isFormLocked={isFormDisabled}
					screenSize={screenSize}
					triggered={triggered}
					hasLineItemSection={hasLineItemSection}
					entityIndex={entityIndex}
					isFormExpandable={isFormExpandable}
					lastDenseRowSection={lastDenseRowSection}
					parentFormName={parentFormName}
					isScheduleFormSection={isScheduleFormSection}
					bookmarkTitle={bookmarkTitle}
					sectionFieldLogic={sectionFieldLogic}
					isEntryInterview={isEntryInterview}
					barcodeName={barcodeName}
					modifiedFormName={modifiedFormName}
					formName={formName}
				/> :
				<Collapse
					key={index}
					title={section.title}
					collapseClass={section.collapseClass || null}
					index={index}
					groups={groups}
					allSections={formSections}
					section={section}
					sectionClass={sectionClass}
					fns={{ saveForm, setUniqueEntityFieldValue, showSharedEntityDialog, updateEntityUniqueValue }}
					isFormPresent={isFormPresent}
					isFormLocked={isFormDisabled}
					screenSize={screenSize}
					triggered={triggered}
					hasLineItemSection={hasLineItemSection}
					entityIndex={entityIndex}
					isFormExpandable={isFormExpandable}
					lastDenseRowSection={lastDenseRowSection}
					parentFormName={parentFormName}
					isScheduleFormSection={isScheduleFormSection}
					bookmarkTitle={bookmarkTitle}
					sectionFieldLogic={sectionFieldLogic}
					isEntryInterview={isEntryInterview}

				/>}
			</React.Fragment> : null
		);
	});

	return !hasLoadingError ? (
		<>
			{isExportForm ? <ExportRenderer
				sections={sections}
				formName={formName}
				passFromEntity={passFromEntity}
				hasLineItemSection={hasLineItemSection}
				entityScrollTab={entityScrollTab}
				entityIndex={entityIndex}
				setUniqueEntityFieldValue={setUniqueEntityFieldValue}
				showSharedEntityDialog={showSharedEntityDialog}
				isFormPresent={isFormPresent}
				isFormExpandable={isFormExpandable}
				isLineItemSection={isLineItemSection}
				parentFormName={parentFormName}
				parentGroup={parentGroup}
				parentIndex={parentIndex}
				isScheduleFormSection={isScheduleFormSection}
				formSections={formSections}
				isFormNA={isFormNA}
				isFormDisabled={isFormDisabled}
				usePageFramework={usePageFramework}
				renderEntity={renderEntity}
				triggered={triggered}
				saveForm={saveForm}
				setFormSections={setFormSections}
				setHasLoadingError={setHasLoadingError}
				setIsLoaded={setIsLoaded}
				mappedSections={Sections}
				sectionFieldLogic={sectionFieldLogic}
				lookups={LOOKUPS}
				isEntryInterview={isEntryInterview}
				bookmarkTitle={bookmarkTitle}
				setBookmarkTitle={setBookmarkTitle}
				year={year}
				dashboardCards={dashboardCards}
				defaultFormName={defaultFormName}
				isExportForm={isExportForm}
			/> :
			<FormRenderer
				sections={sections}
				formName={formName}
				passFromEntity={passFromEntity}
				hasLineItemSection={hasLineItemSection}
				entityScrollTab={entityScrollTab}
				entityIndex={entityIndex}
				setUniqueEntityFieldValue={setUniqueEntityFieldValue}
				showSharedEntityDialog={showSharedEntityDialog}
				isFormPresent={isFormPresent}
				isFormExpandable={isFormExpandable}
				isLineItemSection={isLineItemSection}
				parentFormName={parentFormName}
				parentGroup={parentGroup}
				parentIndex={parentIndex}
				isScheduleFormSection={isScheduleFormSection}
				formSections={formSections}
				isFormNA={isFormNA}
				isFormDisabled={isFormDisabled}
				usePageFramework={usePageFramework}
				renderEntity={renderEntity}
				triggered={triggered}
				saveForm={saveForm}
				setFormSections={setFormSections}
				setHasLoadingError={setHasLoadingError}
				setIsLoaded={setIsLoaded}
				mappedSections={Sections}
				sectionFieldLogic={sectionFieldLogic}
				lookups={LOOKUPS}
				isEntryInterview={isEntryInterview}
				bookmarkTitle={bookmarkTitle}
				setBookmarkTitle={setBookmarkTitle}
				year={year}
			/> }
		</>
	)
	: (<ErrorMessage text='An error occurred while loading your form. Please contact CLA Support for assistance.'/>);
};

export default FormRendererHelper;
