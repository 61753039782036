import usePageFramework from "@utilities/hooks/usePageFramework";
import "./styles.scss";
import _ from "lodash";
import { ARROW_RIGHT_BLUE, ARROW_LEFT_BLUE } from "@utilities/constants/images";
import { NAV_NEXT_CARD_TEXT, NAV_PREV_CARD_TEXT, RETURN_TO_DASHBOARD } from "@utilities/constants/strings";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ReactMDLink } from "react-md";
import useAdaptive from '@utilities/hooks/useAdaptive';

const NavBarFooterMobile = () => {
  const { REDUX, selectState } = usePageFramework();
  const allCards = selectState(REDUX.NAVBAR_CARDS);
  const currentKey = selectState(REDUX.CURRENT_CARD_KEY);
  const organizerId = selectState(REDUX.ORGANIZER_ID);
  const { isMobile } = useAdaptive();

  let cardsArr =
    allCards &&
    allCards.map((category) => {
      return category?.cards;
    });

  let previousRoute = '/';
  let nextRoute = '/';
  let currentIndex;
  cardsArr = _.flatten(cardsArr);
  cardsArr &&
    cardsArr.forEach((element, index) => {
      if (element?.key === currentKey) {
        currentIndex = index;
        previousRoute = cardsArr[index - 1]?.route;
        nextRoute = cardsArr[index + 1]?.route;
      }
    });

  const Link = (props) => {
    return (
      <ReactMDLink
        component={ReactRouterLink}
        {...props}
        style={{ textDecoration: "none", color: "black" }}
      />
    );
  };

  return (
    <div className="form-footer-nav-mobile">
        <div className="min-width prev">
      {currentIndex !== 0 && (
        <Link
          to={previousRoute}
          data-testid= "navPrevious"
          onClick={() => window.scrollTo(0, 0)}
          className={`item-flex prev-link`}
        >
          <span className="icon-circle">
            <img src={ARROW_LEFT_BLUE} alt="arrow-left" />
          </span>
          {!isMobile && <span className="font-style">{NAV_PREV_CARD_TEXT}</span>}
        </Link>
      )}
      </div>
      <Link className="item-flex return-text-link" to={`/dashboard/${organizerId}`}>
        <span className="return-text">{RETURN_TO_DASHBOARD}</span>
      </Link>
      <div className="min-width next">
      {currentIndex+1 !== cardsArr?.length && (
        <Link
          to={nextRoute}
          data-testid= "navNext"
          onClick={() => window.scrollTo(0, 0)}
          className={`item-flex next-link`}
        >
          {!isMobile && <span className="font-style">{NAV_NEXT_CARD_TEXT}</span>}
          <span className="icon-circle">
            <img src={ARROW_RIGHT_BLUE} alt="arrow-right" />
          </span>
        </Link>
      )}
      </div>
    </div>
  );
};

export default NavBarFooterMobile;
