import { useState } from 'react';
import { Grid, GridCell, DropdownMenu, TextIconSpacing, MenuItem } from 'react-md';
import { ExpandMoreSVGIcon } from '@react-md/material-icons';
import { BELOW_INNER_LEFT_ANCHOR } from '@react-md/utils';

import './dashboardSubMenu.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as IMG from '@utilities/constants/images';
// import SearchField from '../searchField/index';

function DashboardSubMenu() {
    const { screenSize, updateVisibleDashboardCards, updateSortedDashboardCards } = usePageFramework();
    const [showItems, setShowItems] = useState('All');
    const [sortItems, setSortItems] = useState('Traditional');

    const itemsSort = [
        <MenuItem key="sort-item-traditional" className="dashboardSubMenuDropdownItem" onClick={() => { setSortItems('Traditional'); updateSortedDashboardCards('traditional'); }}>Traditional</MenuItem>,
        <MenuItem key="sort-item-alphaIncreasing" className="dashboardSubMenuDropdownItem" onClick={() => { setSortItems('A-Z'); updateSortedDashboardCards('alphaIncreasing'); }}>Alphabetical A-Z</MenuItem>,
        <MenuItem key="sort-item-alphaDecreasing" className="dashboardSubMenuDropdownItem" onClick={() => { setSortItems('Z-A'); updateSortedDashboardCards('alphaDecreasing'); }}>Alphabetical Z-A</MenuItem>,
        <MenuItem key="sort-item-progressIncreasing" className="dashboardSubMenuDropdownItem" onClick={() => { setSortItems('Low-High'); updateSortedDashboardCards('progressIncreasing'); }}>Progress: Low-High</MenuItem>,
        <MenuItem key="sort-item-progressDecreasing" className="dashboardSubMenuDropdownItem" onClick={() => { setSortItems('High-Low'); updateSortedDashboardCards('progressDecreasing'); }}>Progress: High-Low</MenuItem>,
    ];

    const itemsShow = [
        <MenuItem key="sort-item-all" className="dashboardSubMenuDropdownItem" onClick={() => { setShowItems('All'); updateVisibleDashboardCards(-1); }}>All</MenuItem>,
        <MenuItem key="sort-item-required" className="dashboardSubMenuDropdownItem" onClick={() => { setShowItems('Required'); updateVisibleDashboardCards(6); }}>Required</MenuItem>,
        <MenuItem key="sort-item-recommended" className="dashboardSubMenuDropdownItem" onClick={() => { setShowItems('Recommended'); updateVisibleDashboardCards(7); }}>Recommended</MenuItem>,
        <MenuItem key="sort-item-completed" className="dashboardSubMenuDropdownItem" onClick={() => { setShowItems('Completed'); updateVisibleDashboardCards(4); }}>Completed</MenuItem>,
        <MenuItem key="sort-item-inProgress" className="dashboardSubMenuDropdownItem" onClick={() => { setShowItems('In Progress'); updateVisibleDashboardCards(2); }}>In Progress</MenuItem>,
        <MenuItem key="sort-item-notStarted" className="dashboardSubMenuDropdownItem" onClick={() => { setShowItems('Not Started'); updateVisibleDashboardCards(1); }}>Not Started</MenuItem>
    ];

    const buttonsLeftAlign = {
        float: 'left',
        textAlign: 'left'
    };

    let colspan = 12;
    let buttons1Align = buttonsLeftAlign;

    switch (screenSize) {
        case 'Phone':
            colspan = 4;
            buttons1Align = buttonsLeftAlign;
            break;
        case 'Tablet':
            colspan = 4;
            break;
        default:
            colspan = 12;
    }

    return (
        <Grid className='dashboardSubMenu'>
            <GridCell className='dashboardSubMenuDropdown' colSpan={colspan} style={buttons1Align}>
                {/* hide searchfield for now until it is fully implemented  */}
                {/* <SearchField placeholder="SEARCH:" iconPosition="right"/>  */}
                <DropdownMenu
                    id="sortMenu"
                    icon={<ExpandMoreSVGIcon className="dashboardSubMenuDropdownIcon" />}
                    anchor={BELOW_INNER_LEFT_ANCHOR}
                    buttonChildren={<TextIconSpacing
                        className="dashboardSubMenuDropdownLabel"
                        icon={<img alt="Sort icon" src={IMG.FILTER_ICON} />}
                        iconBefore
                    >
                        <span className='dashboardSubMenuDropdownSortTextLabel'>Sort:</span>
                        <span className="dashboardSubMenuDropdownSortTextValue">{sortItems}</span>
                    </TextIconSpacing>}
                >
                    {itemsSort}
                </DropdownMenu>
                <DropdownMenu
                    id="showMenu"
                    icon={<ExpandMoreSVGIcon className="dashboardSubMenuDropdownIcon" />}
                    buttonChildren={<TextIconSpacing
                        className="dashboardSubMenuDropdownLabel"
                        icon={<img alt="Show icon" src={IMG.SHOW_ICON} />}
                        iconBefore
                    >
                        <span className='dashboardSubMenuDropdownShowTextLabel'>Show:</span>
                        <span className="dashboardSubMenuDropdownShowTextValue">{showItems}</span>
                    </TextIconSpacing>}
                    anchor={BELOW_INNER_LEFT_ANCHOR}
                >
                    {itemsShow}
                </DropdownMenu>
            </GridCell>
        </Grid>
    );
}

export default DashboardSubMenu;