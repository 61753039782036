function formatStatus(status) {
    if (status.startsWith('Active')) {
      return 'Active';
    } else if (status.startsWith('Inactive')) {
      return 'Inactive';
    } else {
      return '';
    }
  }

  export {
    formatStatus
}