import { MenuItem, Divider } from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import useAdaptive from "@utilities/hooks/useAdaptive";
import { setTaxpayerDetails } from "@utilities/helpers/setTaxPayerDetails";

export const DropdownMenuItem = ({ isEntry, taxPayerName, priorYearReturnId, taxpayerSpouseName, isLastItem, organizer, avatarWidth, avatarHeight, avatarFontSize, dropdownFontSize }) => {
  const { ACTION, dispatch, selectState, REDUX, history, updateUploadList, setEntityFormState, clearFormState } = usePageFramework();
  const currentTaxPayer = selectState(REDUX.TAXPAYER_NAME);
  const { isMobile } = useAdaptive();

  const [initials, setInitials] = useState('');

  const menuItemStyle = {
    width: isEntry ? isMobile ? 'auto' : '394px' : '332px',
    maxWidth: isMobile ? '394px' : 'auto',
    paddingLeft: isEntry ? '23px' : '11px',
    paddingRight: isEntry ? '23px' : '15px',
    paddingTop: '10px',
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
    margin: '0px',
    paddingBottom: '4px',
  };

  const wrapperStyle = {
    width: '100%',
    overflow: 'hidden',
    fontFamily: 'museo-sans',
  };

  const avatarStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: avatarWidth || '45px',
    height: avatarHeight || '45px',
    fontSize: '21px',
    fontWeight: 400,
    color: '#2E334E',
    padding: '6px',
    backgroundColor: '#2E334E',
    borderRadius: '50%',
    marginRight: '16px',
  };

  // Avatar text style
  const avatarTextStyle = {
    color: '#FFFFFF',
    fontSize: avatarFontSize || '21px',
    fontWeight: 400,
  };

  // Style for the content section
  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    marginTop: '2px',
    lineHeight: '26px',
  };

  // Style for the tax payer name
  const nameStyle = {
    color: '#2E334E',
    fontSize: dropdownFontSize || '18px',
    fontWeight: 600,
    margin: '0px',
    lineHeight: isEntry ? '26px' : '16.8px',
    fontFamily: 'museo-sans',
  };

  // Style for the details (Return ID and Spouse)
  const detailStyle = {
    margin: '0px',
    color: '#4B4B4B',
    lineHeight: isEntry ? '20px' : '14.4px',
    fontSize: isEntry ? '14px' : '12px',
  };

  const detailLabelStyle = {
    fontWeight: 600,
    margin: '0px',
  };

  const detailValueStyle = {
    fontWeight: 400,
    margin: '0px',
    fontSize: isEntry ? '14px' : '12px',
  };

  const getInitials = (taxPayerName) => {
    const initials = taxPayerName.split(' ').slice(0, 2).map(part => part[0].toUpperCase()).join('');
    setInitials(initials);
    return initials;
  };

  useEffect(() => {
    if (taxPayerName) getInitials(taxPayerName);
  }, []);

  return (
    <>
      <MenuItem key={priorYearReturnId}
        onClick={() => setTaxpayerDetails({
          isEntry,
          currentTaxPayer,
          organizer,
          ACTION,
          dispatch,
          history,
          updateUploadList,
          setEntityFormState,
          clearFormState
        })}
        style={menuItemStyle}>
        <div style={wrapperStyle}>
          <div style={containerStyle}>
            {/* Avatar section */}
            <div style={avatarStyle}>
              <p style={avatarTextStyle}>{initials}</p>

            </div>

            {/* Content section */}
            <div style={contentStyle}>
              <p style={nameStyle}>{taxPayerName}</p>

              {/* Return ID section */}
              <div style={detailStyle}>
                <span style={detailLabelStyle}>Return ID:</span>
                <span style={detailValueStyle}>{' '}{priorYearReturnId}</span>
              </div>

              {/* Conditionally render the Spouse name if available */}
              {taxpayerSpouseName && (
                <div style={{ ...detailStyle, lineHeight: isEntry ? '16.8px' : '14.4px' }}>
                  <span style={detailLabelStyle}>Spouse:</span>
                  <span style={detailValueStyle}>{' '}{taxpayerSpouseName}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </MenuItem>
      {!isLastItem && <Divider style={{ backgroundColor: '#d8d8d8', height: '0.5px', opacity: 0.5, width: '85%' }} />}
    </>
  );
};
